import {
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest
} from "@angular/common/http";
import { catchError, retry } from "rxjs";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { environment } from "../../environments/environment";

export const authInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
) => {
    let auth = inject(AuthService);
    let token = localStorage.getItem("access_token");

    // FIXME actualy it verify if token exist. if not exist use api directly.
    // When BE side add cookie logic this check can be removed because jwt will be in cookies and not require to be added
    const newReq = token
        ? req.clone({
              setHeaders: {
                  Authorization: `Bearer ${token}`
              }
          })
        : req.clone({});

    if (
        newReq.url.startsWith(environment.auth.tokenUrl) ||
        newReq.url.startsWith(environment.auth.loginUrl)
    ) {
        return next(req);
    }

    //TODO REMOVE
    // if (newReq.url.includes("user") && !token)
    //     return next(newReq).pipe(
    //         switchMap(() =>
    //             throwError(() => ({
    //                 status: 401
    //             }))
    //         ),
    //         catchError((error) => {
    //             return auth.authErrorOnApi(error);
    //         }),
    //         retry(1)
    //     );

    return next(newReq).pipe(
        catchError((error) => auth.authErrorOnApi(error)),
        retry(1)
    );
};
