import {
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest
} from "@angular/common/http";
import { catchError, map, of, retry, switchMap } from "rxjs";
import { inject } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { environment } from "../../environments/environment";

export const authInterceptor: HttpInterceptorFn = (
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
) => {
    let auth = inject(AuthService);

    if (
        req.url.startsWith(environment.auth.tokenUrl) ||
        req.url.startsWith(environment.auth.loginUrl)
    ) {
        return next(req);
    }

    return of(true).pipe(
        map(() => {
            let token = localStorage.getItem("access_token");

            return token
                ? req.clone({
                      setHeaders: {
                          Authorization: `Bearer ${token}`
                      }
                  })
                : req.clone({});
        }),
        switchMap((req) => next(req)),
        catchError((error) => auth.authErrorOnApi(error)),
        retry(1)
    );
};
