import {
    APP_INITIALIZER,
    ApplicationConfig,
    LOCALE_ID,
    importProvidersFrom,
    isDevMode
} from "@angular/core";
import { provideRouter, withRouterConfig } from "@angular/router";

import { routes } from "./app.routes";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { TranslocoHttpLoader } from "./transloco-loader";
import { provideTransloco } from "@ngneat/transloco";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ApiModule, Configuration } from "./api/v1";
import { environment } from "../environments/environment";
import { AuthService } from "./services/auth.service";
import { mockUserInterceptor } from "./interceptors/mockUserInterceptor";
import { authInterceptor } from "./interceptors/authInterceptors";
import { LocaleSettingsService } from "./services/locale-settings.service";
export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            routes,
            withRouterConfig({ paramsInheritanceStrategy: "always" })
        ),
        provideHttpClient(
            withInterceptors(environment.noAuth ? [mockUserInterceptor] : []),
            withInterceptors([authInterceptor])
        ),
        provideAnimations(),
        provideTransloco({
            config: {
                availableLangs: ["en"],
                defaultLang: "en",
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode()
            },
            loader: TranslocoHttpLoader
        }),
        importProvidersFrom(
            ApiModule.forRoot(
                () =>
                    new Configuration({
                        basePath: environment.apiPath
                    })
            )
        ),
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            useFactory: (auth: AuthService) => {},
            deps: [AuthService]
        },
        {
            provide: LOCALE_ID,
            useFactory: (localeService: LocaleSettingsService) => {
                console.log(localeService.getLang());
                return localeService.getLang();
            },
            deps: [LocaleSettingsService]
        }
        // {
        //     provide: RouteReuseStrategy,
        //     useClass: MasRouteReuseStrategy
        // }
    ]
};
